/* eslint-disable no-console */
import Swal from 'sweetalert2';
import { API_ROOT } from './Configuration';
import history from '../history';

export const ROOT = API_ROOT;

export const LOGIN = '/api/Login';
export const LOGOUT = '/api/Logout';

export const CUSTOMER_GETALLCUSTOMERLIST = '​/api​/Customer​/GetAllCustomerList';

export const CUSTOMER_GETCUSTOMERBYLISTID = '/api/Customer/GetCustomerByListID';

export const CUSTOMER_CREATENEWCUSTOMER_SAVE = '​/api/Customer/CreateNewCustomer_Save';

export const JOB_CREATENEWJOB_SAVE = '​/api/Jobs/CreateNewJob_Save';
export const JOB_GETCUSTOMERALLJOB = '​/api/Jobs/GetCustomerAllJobs';

export async function successSwal(title = null) {
  let timerInterval;
  Swal.fire({
    title: `${title || 'Success!'}`,
    icon: 'success',
    // html: 'I will close in <b></b> milliseconds.',
    timer: 3000,
    timerProgressBar: true,
    onBeforeOpen: () => {
      Swal.showLoading();
      timerInterval = setInterval(() => {
        const content = Swal.getContent();
        if (content) {
          const b = content.querySelector('b');
          if (b) {
            b.textContent = Swal.getTimerLeft();
          }
        }
      }, 100);
    },
    onClose: () => {
      clearInterval(timerInterval);
    },
  }).then((result) => {
    /* Read more about handling dismissals below */
    if (result.dismiss === Swal.DismissReason.timer) {
      console.log('I was closed by the timer');
    }
  });
}

export async function errorSwal(
  errorMessage,
  APIurl = null,
  statusCode = null,
  redirectUrl = null,
) {
  const notification = await Swal.fire({
    titleText: `Error${statusCode ? ` ${statusCode}` : ''}`,
    html: `<b>Error Text:</b> ${
      typeof errorMessage === 'object' ? JSON.stringify(errorMessage) : errorMessage
    }${
      APIurl ? `<br />Error at URL: ${APIurl}<br />` : ''
    }<br />Hit Enter, Space, Esc, or click anywhere on the background to ${
      redirectUrl ? `redirect to ${redirectUrl}` : `reload`
    }.`,
    icon: 'error',
    showConfirmButton: false,
    showCancelButton: true,
  });
  if (notification.dismiss !== Swal.DismissReason.cancel) {
    if (redirectUrl) {
      history.push(redirectUrl);
    } else {
      window.location.reload(true);
    }
  } else {
    Swal.fire({
      html: 'Data will not load until you refresh.<br />Please save your work and refresh later.',
    });
  }
}

export async function errorSwalClosePage(
  errorMessage,
  APIurl = null,
  statusCode = null,
  redirectUrl = null,
) {
  const notification = await Swal.fire({
    titleText: `Error${statusCode ? ` ${statusCode}` : ''}`,
    html: `<b>Error Text:</b> ${
      typeof errorMessage === 'object' ? JSON.stringify(errorMessage) : errorMessage
    }`,
    icon: 'error',
    showConfirmButton: false,
    showCancelButton: true,
  });
  if (notification.dismiss !== Swal.DismissReason.cancel) {
    if (redirectUrl) {
      window.opener = null;
      window.open('', '_self');
      window.close();
    } else {
      window.location.reload(true);
    }
  } else {
    window.opener = null;
    window.open('', '_self');
    window.close();
  }
}

export const noRetry = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: localStorage.crmToken,
  },
  interceptors: {
    response: async (response) => {
      if (response.status === 500) {
        errorSwal(response.text, response.url, response.status);
        console.log(
          `Error hitting API at: ${response.url}\n Error ${response.status}: ${response.statusText}`,
        );
      }
      return response;
    },
  },
  retries: 0,
  cachePolicy: 'no-cache',
  suspense: true,
};

export const retryOptions = {
  ...noRetry,
  retries: 3,
  timeout: 10000,
  retryOn: async ({ response }) => {
    return response && response.status >= 300 && response.status !== 401;
  },
};

export const retryAndCache = {
  ...noRetry,
  cachePolicy: 'cache-first',
  retries: 3,
  timeout: 10000,
};
