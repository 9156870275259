/* eslint-disable no-console */
import React, { Suspense, lazy, addCallback } from 'reactn';
import { Route, Switch, Redirect, Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import RingLoader from 'react-spinners/RingLoader';
import { css } from '@emotion/core';
import { Provider as RestProvider } from 'use-http';
import history from './history';
import * as API from './services/API';

// eslint-disable-next-line import/no-unresolved
import 'assets/scss/material-dashboard-pro-react.scss?v=1.8.0';

const AuthLayout = lazy(() => import('./layouts/Auth'));
const RtlLayout = lazy(() => import('./layouts/RTL'));
const AdminLayout = lazy(() => import('./layouts/Admin'));

// Callback should make it so that when you setError to a truthy value,
// a sweetalert message pops up. Right now, it's registering errors before
// anything has the chance to load. Will look at later.
// https://smartadvancedtech.atlassian.net/browse/SAT-869
// addCallback(global => {
//   if (global.error !== null) {
//     let thisError = '';
//     if (typeof global.error === 'object') {
//       thisError = JSON.stringify(global.error);
//     } else {
//       thisError = global.error;
//     }
//     // eslint-disable-next-line no-console
//     console.log(`There was an error with error ${thisError}`);
//     API.errorSwal(thisError);
//     return { error: null };
//   }
//   return null;
// });

// Can be a string as well. Need to ensure each key-value pair ends with ;
const spinnerStyle = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

// eslint-disable-next-line react/prefer-stateless-function
class App extends React.Component {
  render() {
    return (
      <div>
        <Router history={history}>
          <SnackbarProvider maxSnack={3}>
            <RestProvider url={API.ROOT} options={API.noRetry}>
              <Suspense
                fallback={
                  <RingLoader
                    css={spinnerStyle}
                    color="#056496"
                    top="50%"
                    size={150}
                    loading={global.loading}
                  />
                }
              >
                <Switch>
                  <Route path="/rtl" component={RtlLayout} />
                  <Route path="/auth" component={AuthLayout} />
                  <Route path="/admin" component={AdminLayout} />
                  <Redirect from="/" to="/auth/login" />
                </Switch>
              </Suspense>
            </RestProvider>
          </SnackbarProvider>
        </Router>
      </div>
    );
  }
}

export default App;
